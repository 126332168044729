/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MediaFragmentFragmentDoc } from '../../../gql/MediaFragment.document';
export type MediaPostsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.Pagination>;
  filter: Types.FeedPostsFilter;
}>;


export type MediaPostsQuery = { __typename?: 'Query', mediaPosts: { __typename?: 'MediaPostList', pageInfo: { __typename?: 'MediaPostPageInfo', hasNextPage: boolean, endCursor?: string | null }, items: Array<{ __typename?: 'MediaPost', id: string, slug: string, searchParams: string, title: string, subtitle: string, species: Types.PostSpecies, status: Types.PostStatus, commentsCount: number, favorite: boolean, publishAt: number | string, viewsCount: number, isSpecialProject: boolean, tags: Array<{ __typename?: 'Tag', id: string, value: string }>, cover?: { __typename?: 'Cover', originalUrl: string, alt?: string | null, cropSettings?: unknown | null, source?: string | null } | null }> } };


export const MediaPostsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MediaPosts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Pagination"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FeedPostsFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"mediaPosts"},"name":{"kind":"Name","value":"feedPosts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"pagination"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"endCursor"}}]}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFragment"}}]}}]}}]}},...MediaFragmentFragmentDoc.definitions]} as unknown as DocumentNode<MediaPostsQuery, MediaPostsQueryVariables>;